import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ArticleModalComponent } from './components/modal-components/article-modal/article-modal.component';
import { AppRoutingModule } from './app-routing.module';
// Import library module
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SelectTextDirective } from './directives/select-on-click.directive';
import { BasketPreviewComponent } from './components/basket-components/basket-preview/basket-preview.component';
import { SearchBarComponent } from './components/header-components/search-bar/search-bar.component';
//import { Angulartics2RouterlessModule } from 'angulartics2/routerlessmodule';
import { MegaMenuComponent } from './components/header-components/mega-menu/mega-menu.component';
import { ExtraOptions } from '@angular/router';
import { ScreenSaverComponent } from './components/screen-saver-components/screen-saver.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { createTranslateLoader } from './translationLoader';
import { AddToBasketModalComponent } from './components/modal-components/add-to-basket-modal/add-to-basket-modal.component';
import { BasketComponent } from './components/basket-components/basket/basket.component';
import { GroupedArticlesComponent } from './components/basket-components/basket/grouped-articles/grouped-articles.component';
import { AddAllModalComponent } from './components/modal-components/add-all-modal/add-all-modal.component';
import { AddAllArticleComponent } from './components/modal-components/add-all-modal/add-all-article/add-all-article.component';
import { CompareArticlesComponent } from './components/compare-articles-components/compare-articles.component';
import { CheckoutComponent } from './components/basket-components/checkout/checkout.component';
import { SelectArticleComponent } from './components/compare-articles-components/select-article/select-article.component';
import { BasketPreviewArticleComponent } from './components/basket-components/basket-preview/basket-preview-article/basket-preview-article.component';
import { ArticleBomComponent } from './components/article-components/article-bom-components/article-bom.component';
import { CheckoutArticleComponent } from './components/basket-components/checkout/checkout-article/checkout-article.component';
import { NewBasketComponent } from './components/general-components/select-basket/new-basket/new-basket.component';
import { InstantResultComponent } from './components/header-components/instant-result/instant-result.component';
import { BasketsComponent } from './components/basket-components/baskets/baskets.component';
import { BasketItemComponent } from './components/basket-components/baskets/basket-item/basket-item.component';
import { ItemCountComponent } from './components/basket-components/baskets/basket-item/item-count/item-count.component';
import { SelectBasketTypeComponent } from './components/basket-components/baskets/select-basket-type/select-basket-type.component';
import { UsersComponent } from './components/user-components/user-list/user-list.component';
import { SentBasketOverviewComponent } from './components/basket-components/sent-basket-overview/sent-basket-overview.component';
import { BasketPageComponent } from './pages/basket-page/basket-page.component';
import { BasketsPageComponent } from './pages/baskets-page/baskets-page.component';
import { UsersPageComponent } from './pages/users-page/users-page.component';
import { CheckoutPageComponent } from './pages/checkout-page/checkout-page.component';
import { SentBasketOverviewPageComponent } from './pages/sent-basket-overview-page/sent-basket-overview-page.component';
import { UserSettingsPageComponent } from './pages/user-settings-page/user-settings-page/user-settings-page.component';
import { UserSettingsComponent } from './components/user-components/user-settings/user-settings/user-settings.component';
import { EditBasketNameComponent } from './components/basket-components/edit-basket-name/edit-basket-name.component';
import { EditUserModalComponent } from './components/user-components/edit-user-modal/edit-user-modal.component';
import { ConfirmDialogComponent } from './components/modal-components/confirm-dialog/confirm-dialog.component';
import { SelectShippingAddressComponent } from './components/general-components/select-shipping-address/select-shipping-address.component';
import { OpenOrdersComponent } from './components/financial-components/open-orders/open-orders.component';
import { FinancialPageComponent } from './pages/financial-page/financial-page.component';
import { SelectFinancialTypeComponent } from './components/financial-components/select-financial-type/select-financial-type.component';
import { InvoicesComponent } from './components/financial-components/invoices/invoices.component';
import { PackingSlipsComponent } from './components/financial-components/packing-slips/packing-slips.component';
import { FinancialComponent } from './components/financial-components/financial/financial.component';
import { OpenOrderLineComponent } from './components/financial-components/open-orders/open-order-line/open-order-line.component';
import { OpenOrdersFiltersComponent } from './components/financial-components/open-orders/open-orders-filters/open-orders-filters.component';
import { FinancialPaginatorComponent } from './components/financial-components/financial-paginator/financial-paginator.component';
import { NumResultsOpenOrdersComponent } from './components/financial-components/open-orders/num-results-open-orders/num-results-open-orders.component';
import { ViewLeafletComponent } from './components/article-components/view-leaflet/view-leaflet.component';
import { UnlinkPopupComponent } from './components/user-components/user-settings/unlink-popup/unlink-popup.component';
import { UsernameValidator } from './validators/email-username-validator.directive';
import { EditOpenOrderComponent } from './components/financial-components/open-orders/edit-open-order/edit-open-order.component';
import { OpenOrdersOptionsComponent } from './components/financial-components/open-orders/open-orders-options/open-orders-options.component';
import { WidgetsComponent } from './components/home-page-components/widgets/widgets.component';
import { NewsDetailsComponent } from './components/cms/news-details/news-details.component';
import { DetailsModalComponent } from './components/cms/details-modal/details-modal.component';
import { ModalContentDirective } from './directives/modal-content.directive';
import { AlertCounterComponent } from './components/header-components/alert-counter/alert-counter.component';
import { InvoiceLineComponent } from './components/financial-components/invoices/invoice-line/invoice-line.component';
import { InvoicesFiltersComponent } from './components/financial-components/invoices/invoices-filters/invoices-filters.component';
import { InvoiceBalanceOverviewComponent } from './components/financial-components/invoices/invoice-balance-overview/invoice-balance-overview.component';
import { PackingSlipLineComponent } from './components/financial-components/packing-slips/packing-slip-line/packing-slip-line.component';
import { PackingSlipsFiltersComponent } from './components/financial-components/packing-slips/packing-slips-filters/packing-slips-filters.component';
import { PackingSlipViewerComponent } from './components/financial-components/packing-slips/packing-slip-viewer/packing-slip-viewer.component';
import { RecentSearchedComponent } from './components/search-components/recent-searched/recent-searched.component';
import { NewsPageComponent } from './pages/news-page/news-page.component';
import { NewsOverviewComponent } from './components/cms/news-overview/news-overview.component';
import { SearchDealersComponent } from './components/helpdesk/search-dealers/search-dealers.component';
import { HelpdeskPageComponent } from './pages/helpdesk-page/helpdesk-page.component';
import { DealerListItemComponent } from './components/helpdesk/search-dealers/dealer-list-item/dealer-list-item.component';
import { ProfilesPageComponent } from './pages/profiles-page/profiles-page.component';
import { ProfilesComponent } from './components/user-components/profiles/profiles.component';
import { ProfileItemComponent } from './components/user-components/profiles/profile-item/profile-item.component';
import { DownloadOpenOrderComponent } from './components/financial-components/open-orders/download-open-order/download-open-order.component';
import { EditProfileModalComponent } from './components/user-components/profiles/edit-profile-modal/edit-profile-modal.component';
import { SearchDealerModalComponent } from './components/helpdesk/search-dealer-modal/search-dealer-modal.component';
import { UserOptionsComponent } from './components/helpdesk/user-options/user-options.component';
import { SelectedDealerNavBarComponent } from './components/helpdesk/selected-dealer-nav-bar/selected-dealer-nav-bar.component';
import { BasketOptionComponent } from './components/basket-components/basket/basket-option/basket-option.component';
import Bugsnag from '@bugsnag/js';
import { environment } from './../environments/environment';
import { WarrantyLeasingComponent } from './components/warranty/warranty-wizard/warranty-leasing/warranty-leasing.component';
import 'hammerjs';
import * as versionData from "./../assets/version.json";


Bugsnag.start({
  apiKey: '00eecc085a1cc2d84e3ca17a9556e802',
  autoDetectErrors: false,
  autoTrackSessions: false,
  enabledReleaseStages: ['test', 'production', 'acceptance'],
  releaseStage: environment.name,
  appVersion: String(versionData['default']),
  user: {
    id: localStorage.getItem('accountCode')
      ? localStorage.getItem('accountCode')
      : '',
  },
  onError: (event) => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(event.originalError.message)) {
      window.location.reload();
      return false;
    }
  },
});

import { SetItemModalComponent } from './components/article-components/set-items/set-item-modal/set-item-modal.component';
import { FooterComponent } from './components/footer/footer.component';
import { MandatoryRequiredLabelComponent } from './components/article-components/mandatory-required-label/mandatory-required-label.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { PreorderBasketComponent } from './components/basket-components/preorder-basket/preorder-basket.component';
import { PreorderBasketPageComponent } from './pages/preorder-basket-page/preorder-basket-page.component';
import { EditPreorderBasketComponent } from './components/basket-components/preorder-basket/edit-preorder-basket/edit-preorder-basket.component';
import { PreorderAlertComponent } from './components/home-page-components/preorder-alert/preorder-alert.component';
import { PromotionBlockComponent } from './components/home-page-components/promotions/promotion-block/promotion-block.component';
import { NotImplementedPageComponent } from './pages/not-implemented-page/not-implemented-page.component';
import { SelectArticleTypeComponent } from './components/basket-components/select-article-type/select-article-type.component';
import { BasketPaymentConditionsComponent } from './components/basket-components/basket-payment-conditions/basket-payment-conditions.component';
import { LogoComponent } from './components/general/logo/logo.component';
import { EditBasketArticleModalComponent } from './components/basket-components/edit-basket-article-modal/edit-basket-article-modal.component';
import { WarrantyPageComponent } from './pages/warranty-page/warranty-page.component';
import { WarrantyOverviewComponent } from './components/warranty/warranty-overview/warranty-overview.component';
import { WarrantyRequestComponent } from './components/warranty/warranty-overview/warranty-request/warranty-request.component';
import { WarrantyDetailsComponent } from './components/warranty/warranty-details/warranty-details.component';
import { WarrantyWizardComponent } from './components/warranty/warranty-wizard/warranty-wizard.component';
import { WarrantyDetailsArticleComponent } from './components/warranty/warranty-details/warranty-details-article/warranty-details-article.component';
import { WarrantySendDetailsComponent } from './components/warranty/warranty-send-details/warranty-send-details.component';
import { SelectPickupDateComponent } from './components/warranty/warranty-send-details/select-pickup-date/select-pickup-date.component';
import { WarrantyLabelsComponent } from './components/warranty/warranty-labels/warranty-labels.component';
import { SelectForWarrantyShipmentComponent } from './components/warranty/select-for-warranty-shipment/select-for-warranty-shipment.component';
import { WarrantyRequestsContainerComponent } from './components/warranty/warranty-requests-container/warranty-requests-container.component';
import { WarrantyGuaranteeComponent } from './components/warranty/warranty-wizard/warranty-guarantee/warranty-guarantee.component';
import { WarrantyGeneralInputsComponent } from './components/warranty/warranty-wizard/form/warranty-general-inputs/warranty-general-inputs.component';
import { WarrantyFramenumberInputComponent } from './components/warranty/warranty-wizard/form/warranty-framenumber-input/warranty-framenumber-input.component';
import { BicycleFinderComponent } from './components/warranty/warranty-wizard/bicycle-finder/bicycle-finder.component';
import { WarrantyReturnsComponent } from './components/warranty/warranty-wizard/warranty-returns/warranty-returns.component';
import { WarrantyCallbackComponent } from './components/warranty/warranty-wizard/warranty-callback/warranty-callback.component';
import { WarrantyArticleDescriptionInputComponent } from './components/warranty/warranty-wizard/form/warranty-article-description-input/warranty-article-description-input.component';
import { WarrantyComplainDescriptionInputsComponent } from './components/warranty/warranty-wizard/form/warranty-complain-description-inputs/warranty-complain-description-inputs.component';
import { WarrantyFileUploadComponent } from './components/warranty/warranty-wizard/form/warranty-file-upload/warranty-file-upload.component';
import { ArticleFinderModalComponent } from './components/search-components/article-finder-modal/article-finder-modal.component';
import { WarrantyGuaranteeDateComponent } from './components/warranty/warranty-wizard/form/warranty-guarantee-date/warranty-guarantee-date.component';
import { PackingSlipFinderComponent } from './components/warranty/warranty-wizard/packing-slip-finder/packing-slip-finder.component';
import { PackingSlipArticleListComponent } from './components/warranty/warranty-wizard/packing-slip-article-list/packing-slip-article-list.component';
import { TurnoverStatisticsPageComponent } from './pages/turnover-statistics-page/turnover-statistics-page.component';
import { YearAgreementComponent } from './pages/year-agreement/year-agreement.component';
import { YearAgreementContainerComponent } from './components/year-agreement/bikes/year-agreement-container/year-agreement-container.component';
import { YearAgreementBrandSelectionComponent } from './components/year-agreement/bikes/year-agreement-brand-selection/year-agreement-brand-selection.component';
import { YearAgreementTabComponent } from './components/year-agreement/bikes/year-agreement-tab/year-agreement-tab.component';
import { YearAgreementTabSectionComponent } from './components/year-agreement/bikes/year-agreement-tab/year-agreement-tab-section/year-agreement-tab-section.component';
import { InputLabelComponent } from './components/year-agreement/bikes/year-agreement-tab/year-agreement-tab-section/input-label/input-label.component';
import { YearAgreementGroupComponent } from './components/year-agreement/bikes/year-agreement-tab/year-agreement-group/year-agreement-group.component';
import { YearAgreementModelsSelectionComponent } from './components/year-agreement/bikes/year-agreement-models-selection/year-agreement-models-selection.component';
import { PurchaseDistributionComponent } from './components/year-agreement/bikes/purchase-distribution/purchase-distribution.component';
import { PurchaseDistributionTableComponent } from './components/year-agreement/bikes/purchase-distribution/purchase-distribution-table/purchase-distribution-table.component';
import { BonusTabComponent } from './components/year-agreement/bikes/bonus-tab/bonus-tab.component';
import { AffiliateInformationComponent } from './components/year-agreement/shared/dealer-information/affiliate-information/affiliate-information.component';
import { NewAgreementComponent } from './components/year-agreement/bikes/new-agreement/new-agreement.component';
import { ShowDealerAgreementsComponent } from './components/year-agreement/bikes/show-dealer-agreements/show-dealer-agreements.component';
import { WarrantyBatteryComponent } from './components/warranty/warranty-wizard/warranty-battery/warranty-battery.component';
import { InfiniteScrollComponent } from './components/general-components/infinite-scroll/infinite-scroll.component';
import { PendingRequestsComponent } from './components/header-components/test-rides-counter/pending-requests.component';
import { ShowCompareButtonComponent } from './components/general-components/show-compare-button/show-compare-button.component';
import { SharedModule } from './modules/shared/shared.module';
import { WarrantyRequestFormComponent } from './components/warranty/warranty-request-form/warranty-request-form.component';
import { WarrantyLabelCardComponent } from './components/warranty/warranty-labels/warranty-label-card/warranty-label-card.component';
import { QuickorderModule } from './modules/quickorder/quickorder.module';
import { PricelabelsModule } from './modules/pricelabels/pricelabels.module';
import { EbscModule } from './modules/ebsc/ebsc.module';
import { EbikePdfContainerComponent } from './components/ebsc/ebike-pdf-container/ebike-pdf-container.component';
import { SuccessModalComponent } from './components/ebsc/success-modal/success-modal.component';
import { LotteryRegistrationPageComponent } from './pages/lottery-registration-page/lottery-registration-page.component';
import { LotteryRegistrationComponent } from './components/lottery-registration/lottery-registration.component';
import { SlideshowsOverviewComponent } from './components/cms/slideshows-overview/slideshows-overview.component';
import { SlideshowsOverviewPageComponent } from './pages/slideshows-overview-page/slideshows-overview-page.component';
import { SlideshowModalComponent } from './components/cms/slideshow-modal/slideshow-modal.component';
import { SlideComponent } from './components/cms/slide/slide.component';
import { SelectCouponModalComponent } from './components/basket-components/select-coupon-modal/select-coupon-modal.component';
import { LeaseModelsComponent } from './components/general-components/lease-models/lease-models.component';
import { OutdatedBrowserPageComponent } from './pages/outdated-browser-page/outdated-browser-page.component';
import { BalanceComponent } from './components/financial-components/balance/balance.component';
import { BalanceLineComponent } from './components/financial-components/balance/balance-line/balance-line.component';
import { BalanceFiltersComponent } from './components/financial-components/balance/balance-filters/balance-filters.component';
import { WarrantyBatteryCallbackComponent } from './components/warranty/warranty-wizard/warranty-battery-callback/warranty-battery-callback.component';
import { ShippingCostOverviewComponent } from './components/basket-components/shipping-cost-overview/shipping-cost-overview.component';
import { ChangePasswordModalComponent } from './components/user-components/user-settings/change-password-modal/change-password-modal.component';
import { SlideshowVideoEmbedComponent } from './components/cms/slideshow-media-embed/slideshow-media-embed.component';
import { RegisterOktaComponent } from './components/okta/register-okta/register-okta.component';
import { RegisterOktaPageComponent } from './pages/register-okta-page/register-okta-page.component';
import { MergeDealersPageComponent } from './pages/merge-dealers-page/merge-dealers-page.component';
import { VerifyPageComponent } from './pages/verify-page/verify-page.component';
import { QrScannerComponent } from './components/search-components/qr-scanner/qr-scanner.component';
import { QrScannerInputComponent } from './components/search-components/qr-scanner-input/qr-scanner-input.component';
import { WishlistModalComponent } from './components/preorder-components/wishlist-modal/wishlist-modal.component';
import { BicycleLeaseDetailsComponent } from './components/lease/bicycle-lease-details/bicycle-lease-details.component';
import { MandatorySelectModalComponent } from './components/basket-components/basket/mandatory-select-modal/mandatory-select-modal.component';
import { PdfViewerComponent } from './components/general-components/pdf-viewer/pdf-viewer.component';
import { ContactDetailsPageComponent } from './pages/contact-details-page/contact-details-page.component';
import { CompanyDataPageComponent } from './pages/company-data-page/company-data-page.component';
import { AddLeaseEmployerComponent } from './components/lease/add-lease-employer/add-lease-employer.component';
import { DiscountSheetPageComponent } from './pages/discount-sheet-page/discount-sheet-page.component';
import { SupplyAndDemandPageComponent } from './pages/supply-and-demand-page/supply-and-demand-page.component';
import { MyAdvertsComponent } from './components/supply-and-demand/my-adverts/my-adverts.component';
import { NewSupplyComponent } from './components/supply-and-demand/my-adverts/new-supply/new-supply.component';
import { NewDemandComponent } from './components/supply-and-demand/my-adverts/new-demand/new-demand.component';
import { MySupplyComponent } from './components/supply-and-demand/my-adverts/my-supply/my-supply.component';
import { MyDemandComponent } from './components/supply-and-demand/my-adverts/my-demand/my-demand.component';
import { SupplyComponent } from './components/supply-and-demand/supply/supply.component';
import { DemandComponent } from './components/supply-and-demand/demand/demand.component';
import { NgbActiveModal, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { EditDemandComponent } from './components/supply-and-demand/edit-demand/edit-demand.component';
import { EditSupplyComponent } from './components/supply-and-demand/edit-supply/edit-supply.component';
import { BasketSumByBrandComponent } from './components/basket-components/basket-sum-by-brand/basket-sum-by-brand.component';
import { DealerCardComponent } from './components/supply-and-demand/dealer-card/dealer-card.component';
import { SearchMarketplaceResultsComponent } from './components/supply-and-demand/search-marketplace/search-marketplace.component';
import { AddAffiliateComponent } from './components/year-agreement/bikes/add-affiliate/add-affiliate.component';
import { YearAgreementContainerPartsComponent } from './components/year-agreement/parts/year-agreement-container-parts/year-agreement-container-parts.component';
import { DealerCommercialPartnerInformationComponent } from './components/year-agreement/parts/dealer-commercial-partner-information/dealer-commercial-partner-information.component';
import { DealerCommercialPartnerSettingsComponent } from './components/year-agreement/parts/dealer-commercial-partner-settings/dealer-commercial-partner-settings.component';
import { DealerPartsBrandsSelectionComponent } from './components/year-agreement/parts/dealer-parts-brands-selection/dealer-parts-brands-selection.component';
import { YearAgreementDealerShipsComponent } from './components/year-agreement/parts/year-agreement-dealer-ships/year-agreement-dealer-ships.component';
import { DrgConditionsComponent } from './components/year-agreement/parts/drg-conditions/drg-conditions.component';
import { DealerPartsBonusSelectionComponent } from './components/year-agreement/parts/dealer-parts-bonus-selection/dealer-parts-bonus-selection.component';
import { KioskAddToBasketModalComponent } from './components/kiosk/kiosk-add-to-basket-modal/kiosk-add-to-basket-modal.component';
import { FavoritesButtonComponent } from './components/header-components/favorites-button/favorites-button.component';
import { LeaveKioskModeModalComponent } from './components/kiosk/leave-kiosk-mode-modal/leave-kiosk-mode-modal.component';
import { KioskEcommerceArticlesComponent } from './components/kiosk/kiosk-ecommerce-articles/kiosk-ecommerce-articles.component';
import { PaymentSuccessComponent } from './components/basket-components/checkout/payment-success/payment-success.component';
import { UnmergeDealerPageComponent } from './pages/unmerge-dealer-page/unmerge-dealer-page.component';
import { SelectPartsDeliveryWeekComponent } from './components/basket/select-parts-delivery-week/select-parts-delivery-week.component';
import { PickupAddressSelectComponent } from './components/warranty/pickup-address-select/pickup-address-select.component';
import { MobileNavigationComponent } from './components/header-components/mobile-navigation/mobile-navigation.component';
import { NdaCheckComponent } from './components/preorder-components/nda-check/nda-check.component';
import { ResendOktaActivationModalComponent } from './components/helpdesk/resend-okta-activation-modal/resend-okta-activation-modal.component';
import { ResetOktaActivationModalComponent } from './components/helpdesk/reset-okta-activation-modal/reset-okta-activation-modal.component';
import { ResetPasswordComponent } from './components/login-components/reset-password/reset-password.component';
import { LoginComponent } from './components/login-components/login/login.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { StockNotificationsContainerComponent } from './components/stock-notifications/stock-notifications-container/stock-notifications-container.component';
import { StockNotificationComponent } from './components/stock-notifications/stock-notification/stock-notification.component';
import { BackorderAlerterComponent } from './components/basket-components/basket/backorder-alerter/backorder-alerter.component';
import { AddToStockNotificationButtonComponent } from './components/stock-notifications/add-to-stock-notification-button/add-to-stock-notification-button.component';
import { PreorderArticleModalComponent } from './components/modal-components/preorder-article-modal/preorder-article-modal.component';
import { EditOpenOrderChangeBatteryComponent } from './components/financial-components/open-orders/edit-open-order-change-battery/edit-open-order-change-battery.component';
import { DeletedBasketOverviewComponent } from './components/basket-components/deleted-basket-overview/deleted-basket-overview.component';
import { DealerOverviewSectionComponent } from './components/dealer-overview-container/dealer-overview-section/dealer-overview-section.component';
import { ArticlesInBackorderOverviewComponent } from './components/basket-components/basket/articles-in-backorder-overview/articles-in-backorder-overview.component';
import { BarcodeComponent } from './pages/barcode/barcode.component';
import { BarCodeScannerComponent } from './components/general-components/bar-code-scanner/bar-code-scanner.component';
import { SelectCheckoutShippingDateComponent } from './components/basket-components/checkout/select-checkout-shipping-date/select-checkout-shipping-date.component';
import { SelectLinkedDealerComponent } from './components/login-components/select-linked-dealer/select-linked-dealer.component';
import { AccentryLogoComponent } from './components/general/logo/accentry-logo/accentry-logo.component';
import { WarrantyManfacturerNotifyComponent } from './components/warranty/warranty-manfacturer-notify/warranty-manfacturer-notify.component';
import { ReplenishmentSelectionModalComponent } from './components/modal-components/replenishment-selection-modal/replenishment-selection-modal.component';
import { SearchMenuComponent } from './components/header-components/search-menu/search-menu.component';
import { MigrateAccountComponent } from './components/migratie-account/migrate-account.component';
import { DeliverWhenAllInStockComponent } from './components/basket-components/deliver-when-all-in-stock/deliver-when-all-in-stock.component';
import { ThreeDeeViewerModalComponent } from './components/article-components/three-dee-viewer/three-dee-viewer-modal/three-dee-viewer-modal.component';
import { GroupSelectHomepageComponent } from './components/group-select-homepage/group-select-homepage.component';
import { BrandSelectorLargeComponent } from './components/general/brand-selector-modal/brand-selector-large.component';
import { StoryblokDirective } from './directives/storyblok.directive';
import { PageComponent } from './pages/page/page.component';
import { BannerComponent } from './components/storyblok/banner/banner.component';
import { StoryblokPageComponent } from './pages/storyblok-page/storyblok-page.component';
import { DynamicModule } from 'ng-dynamic-component';
import { StoryblokElementsContainerComponent } from './components/storyblok/storyblok-elements-container/storyblok-elements-container.component';
import { ArticleContainerComponent } from './components/storyblok/article-container/article-container.component';
import { LinkButtonComponent } from './components/storyblok/link-button/link-button.component';
import { UncheckedArticlesNoticeComponent } from './components/checkout/unchecked-articles-notice/unchecked-articles-notice.component';
import { StoryBlokPreviewPageComponent } from './components/storyblok/story-blok-preview-page/story-blok-preview-page.component';
import { DynamicGridComponent } from './components/storyblok/dynamic-grid/dynamic-grid.component';
import { GridBlockComponent } from './components/storyblok/grid-block/grid-block.component';
import { VideoComponent } from './components/storyblok/video/video.component';
import { CarouselPreviewComponent } from './components/storyblok/carousel-preview/carousel-preview.component';
import { SaveFiltersComponent } from './components/search-components/side-bar/save-filters/save-filters.component';
import { RecallPageComponent } from './pages/recall-page/recall-page.component';
import { YearAgreementPAndAComponent } from './components/year-agreement/p-and-a/year-agreement-pand-a/year-agreement-p-and-a.component';
import { ImageComponent } from './components/storyblok/image/image.component';
import { ArticleImageLightboxComponent } from './components/article-components/article-image-lightbox/article-image-lightbox.component';
import { EzyRiderInvoiceComponent } from './components/warranty/warranty-wizard/form/ezy-rider-invoice/ezy-rider-invoice.component';
import { SlideContentComponent } from './components/cms/slide/slide-content/slide-content.component';
import { ImageBankConditionsModalComponent } from './components/image-bank-components/image-bank-conditions-modal/image-bank-conditions-modal.component';
import { RecallKogaComponent } from './components/recall-koga/recall-koga.component';
import { CatalogueNavbarComponent } from './components/header-components/catalogue-navbar/catalogue-navbar.component';
import { CatalogueDropdownComponent } from './components/header-components/catalogue-dropdown/catalogue-dropdown.component';
import { CommonModule } from '@angular/common';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { StyleAnlComponent } from './components/companyStyles/style-anl/style-anl.component';
import { StyleGhoComponent } from './components/companyStyles/style-gho/style-gho.component';
import { BasketSkeletonLoaderComponent } from './components/basket-components/basket-skeleton-loader/basket-skeleton-loader.component';
import { ShowroomBlockerComponent } from './components/general-components/showroom-blocker/showroom-blocker.component';
import { AdminToolsComponent } from './components/adminTools/admin-tools/admin-tools.component';
import { AdminSidebarComponent } from './components/adminTools/admin-sidebar/admin-sidebar.component';
import { AdminLinksComponent } from './components/adminTools/admin-links/admin-links.component';
import { TestSettingsComponent } from './components/adminTools/test-settings/test-settings.component';
import { JunckerPlusComponent } from './pages/juncker-plus/juncker-plus.component';
import { JunckerLoyaltyCountComponent } from './components/juncker-loyalty-count/juncker-loyalty-count.component';
import { RedirectToJunckerPlusComponent } from './components/juncker-plus/redirect-to-juncker-plus/redirect-to-juncker-plus.component';
import { AppDefaultComponent } from './app-default.component';
import { DownloaderComponent } from './components/downloader/downloader.component';
import { SleighComponent } from './components/sleigh/sleigh.component';

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
};


class errorHandlerFactory implements ErrorHandler {
  handleError(error) {
    Bugsnag.notify(error);
    console.error(error);
  }
}

@NgModule({
  imports: [
    RouterModule,
    //  LoadingBarModule,
    BrowserModule,
    DynamicModule,
    //  SwiperModule,
    BrowserAnimationsModule,
    HttpClientModule,
    //  NgxHmCarouselModule,
    FormsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    // LoadingBarHttpClientModule,
    // UiSwitchModule,
    NgClickOutsideDirective,
    // ReactiveFormsModule,
    SharedModule,
    HammerModule,
    // BarcodeScannerLivestreamModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient],
        },
    }),
    // added to imports
    // Angulartics2Module.forRoot({
    //   pageTracking: {
    //     clearQueryParams: false,
    //   },
    // }),
    QuickorderModule,
    PricelabelsModule,
    EbscModule,
],
  declarations: [
    AppDefaultComponent,
    LoginComponent,
    LoginPageComponent,
    ResetPasswordComponent,
    /* Header */
    SearchBarComponent,
    InstantResultComponent,
    MegaMenuComponent,

    /* Directives */
    SelectTextDirective,

    /* Screensaver */
    ScreenSaverComponent,

    ArticleModalComponent,

    /* Compare articles */
    CompareArticlesComponent,

    /* General */
    SelectArticleComponent,

    /* Add to basket */
    AddAllModalComponent,
    AddAllArticleComponent,
    AddToBasketModalComponent,
    KioskAddToBasketModalComponent,

    /* Basket - Checkout */
    CheckoutComponent,
    BasketComponent,
    BasketPreviewComponent,
    BasketPreviewArticleComponent,
    CheckoutArticleComponent,
    NewBasketComponent,
    BasketsComponent,
    BasketItemComponent,
    ItemCountComponent,
    SelectBasketTypeComponent,
    SentBasketOverviewComponent,
    GroupedArticlesComponent,

    /* Users */
    UsersComponent,

    BasketPageComponent,
    SentBasketOverviewPageComponent,
    BasketsPageComponent,
    UsersPageComponent,
    CheckoutPageComponent,
    UserSettingsPageComponent,
    UserSettingsComponent,
    EditBasketNameComponent,

    EditUserModalComponent,
    ConfirmDialogComponent,
    SelectShippingAddressComponent,
    OpenOrdersComponent,
    FinancialPageComponent,
    SelectFinancialTypeComponent,
    InvoicesComponent,
    PackingSlipsComponent,
    FinancialComponent,
    OpenOrderLineComponent,
    OpenOrdersFiltersComponent,
    FinancialPaginatorComponent,
    NumResultsOpenOrdersComponent,
    ViewLeafletComponent,
    UnlinkPopupComponent,
    EditOpenOrderComponent,
    OpenOrdersOptionsComponent,
    WidgetsComponent,

    DetailsModalComponent,
    ModalContentDirective,
    AlertCounterComponent,
    InvoiceLineComponent,
    InvoicesFiltersComponent,
    InvoiceBalanceOverviewComponent,
    PackingSlipLineComponent,
    PackingSlipsFiltersComponent,
    PackingSlipViewerComponent,
    RecentSearchedComponent,
    NewsPageComponent,
    NewsOverviewComponent,
    SearchDealersComponent,
    HelpdeskPageComponent,
    DealerListItemComponent,
    ProfilesPageComponent,
    ProfilesComponent,
    ProfileItemComponent,
    DownloadOpenOrderComponent,
    EditProfileModalComponent,
    SearchDealerModalComponent,
    SelectedDealerNavBarComponent,
    UserOptionsComponent,
    BasketOptionComponent,
    FooterComponent,
    SetItemModalComponent,
    MandatoryRequiredLabelComponent,
    ErrorPageComponent,
    PreorderBasketComponent,
    PreorderBasketPageComponent,
    EditPreorderBasketComponent,
    PreorderAlertComponent,
    PromotionBlockComponent,
    NotImplementedPageComponent,
    SelectArticleTypeComponent,
    BasketPaymentConditionsComponent,
    LogoComponent,
    EditBasketArticleModalComponent,
    WarrantyPageComponent,
    WarrantyOverviewComponent,
    WarrantyRequestComponent,
    WarrantyDetailsComponent,
    WarrantyWizardComponent,
    WarrantyDetailsArticleComponent,
    WarrantySendDetailsComponent,
    SelectPickupDateComponent,
    WarrantyLabelsComponent,
    SelectForWarrantyShipmentComponent,
    WarrantyRequestsContainerComponent,
    WarrantyGuaranteeComponent,
    WarrantyGeneralInputsComponent,
    WarrantyFramenumberInputComponent,
    BicycleFinderComponent,
    WarrantyReturnsComponent,
    WarrantyCallbackComponent,
    WarrantyArticleDescriptionInputComponent,
    WarrantyComplainDescriptionInputsComponent,
    WarrantyFileUploadComponent,
    ArticleFinderModalComponent,
    WarrantyGuaranteeDateComponent,
    PackingSlipFinderComponent,
    PackingSlipArticleListComponent,
    TurnoverStatisticsPageComponent,
    YearAgreementComponent,
    YearAgreementContainerComponent,
    YearAgreementBrandSelectionComponent,
    YearAgreementTabComponent,
    YearAgreementTabSectionComponent,
    InputLabelComponent,
    YearAgreementGroupComponent,
    YearAgreementModelsSelectionComponent,
    PurchaseDistributionComponent,
    PurchaseDistributionTableComponent,
    BonusTabComponent,
    AffiliateInformationComponent,
    NewAgreementComponent,
    ShowDealerAgreementsComponent,
    WarrantyBatteryComponent,
    InfiniteScrollComponent,
    PendingRequestsComponent,
    ShowCompareButtonComponent,
    EbikePdfContainerComponent,
    SuccessModalComponent,
    LotteryRegistrationPageComponent,
    LotteryRegistrationComponent,
    WarrantyRequestFormComponent,
    WarrantyLabelCardComponent,
    SlideshowsOverviewComponent,
    SlideshowsOverviewPageComponent,
    SlideshowModalComponent,
    SlideComponent,
    SelectCouponModalComponent,
    LeaseModelsComponent,
    OutdatedBrowserPageComponent,
    RegisterOktaComponent,
    BalanceComponent,
    BalanceLineComponent,
    BalanceFiltersComponent,
    WarrantyBatteryCallbackComponent,
    ShippingCostOverviewComponent,
    ChangePasswordModalComponent,
    SlideshowVideoEmbedComponent,
    RegisterOktaPageComponent,
    MigrateAccountComponent,
    MergeDealersPageComponent,
    WarrantyLeasingComponent,
    VerifyPageComponent,
    QrScannerComponent,
    QrScannerInputComponent,
    WishlistModalComponent,
    BicycleLeaseDetailsComponent,
    MandatorySelectModalComponent,
    PdfViewerComponent,
    ContactDetailsPageComponent,
    CompanyDataPageComponent,
    AddLeaseEmployerComponent,
    DiscountSheetPageComponent,
    SupplyAndDemandPageComponent,
    MyAdvertsComponent,
    NewSupplyComponent,
    NewDemandComponent,
    MySupplyComponent,
    MyDemandComponent,
    SupplyComponent,
    DemandComponent,
    EditDemandComponent,
    EditSupplyComponent,
    BasketSumByBrandComponent,
    DealerCardComponent,
    SearchMarketplaceResultsComponent,
    AddAffiliateComponent,
    YearAgreementContainerPartsComponent,
    DealerCommercialPartnerInformationComponent,
    DealerCommercialPartnerSettingsComponent,
    DealerPartsBrandsSelectionComponent,
    YearAgreementDealerShipsComponent,
    DrgConditionsComponent,
    DealerPartsBonusSelectionComponent,
    FavoritesButtonComponent,
    LeaveKioskModeModalComponent,
    KioskEcommerceArticlesComponent,
    PaymentSuccessComponent,
    UnmergeDealerPageComponent,
    SelectPartsDeliveryWeekComponent,
    PickupAddressSelectComponent,
    AppDefaultComponent,
    MobileNavigationComponent,
    NdaCheckComponent,
    ResendOktaActivationModalComponent,
    ResetOktaActivationModalComponent,
    StockNotificationComponent,
    StockNotificationsContainerComponent,
    BackorderAlerterComponent,
    PreorderArticleModalComponent,
    EditOpenOrderChangeBatteryComponent,
    DeletedBasketOverviewComponent,
    ArticlesInBackorderOverviewComponent,
    BarcodeComponent,
    BarCodeScannerComponent,
    SelectCheckoutShippingDateComponent,
    SelectLinkedDealerComponent,
    AccentryLogoComponent,
    SleighComponent,
    WarrantyManfacturerNotifyComponent,
    ReplenishmentSelectionModalComponent,
    SearchMenuComponent,
    DeliverWhenAllInStockComponent,
    ThreeDeeViewerModalComponent,
    GroupSelectHomepageComponent,
    BrandSelectorLargeComponent,
    StoryblokDirective,
    PageComponent,
    BannerComponent,
    StoryblokPageComponent,
    StoryblokElementsContainerComponent,
    ArticleContainerComponent,
    LinkButtonComponent,
    UncheckedArticlesNoticeComponent,
    StoryBlokPreviewPageComponent,
    DynamicGridComponent,
    GridBlockComponent,
    VideoComponent,
    CarouselPreviewComponent,
    SaveFiltersComponent,
    RecallPageComponent,
    YearAgreementPAndAComponent,
    ImageComponent,
    ArticleImageLightboxComponent,
    EzyRiderInvoiceComponent,
    SlideContentComponent,
    ImageBankConditionsModalComponent,
    RecallKogaComponent,
    CatalogueNavbarComponent,
    CatalogueDropdownComponent,
    StyleAnlComponent,
    StyleGhoComponent,
    BasketSkeletonLoaderComponent,
    ShowroomBlockerComponent,
    AdminToolsComponent,
    AdminSidebarComponent,
    AdminLinksComponent,
    TestSettingsComponent,
    JunckerPlusComponent,
    JunckerLoyaltyCountComponent,
    RedirectToJunckerPlusComponent,
    DownloaderComponent,
    AppComponent
  ],
  providers: [
    UsernameValidator,
    { provide: ErrorHandler, useClass: errorHandlerFactory },
    NgbActiveModal,
  ],
  bootstrap: [AppComponent],
  exports:[
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    AppDefaultComponent,
    LoginComponent,
    LoginPageComponent,
    ResetPasswordComponent,
    /* Header */
    SearchBarComponent,
    InstantResultComponent,
    MegaMenuComponent,

    /* Directives */
    SelectTextDirective,

    /* Screensaver */
    ScreenSaverComponent,

    ArticleModalComponent,

    /* Compare articles */
    CompareArticlesComponent,

    /* General */
    SelectArticleComponent,

    /* Add to basket */
    AddAllModalComponent,
    AddAllArticleComponent,
    AddToBasketModalComponent,
    KioskAddToBasketModalComponent,

    /* Basket - Checkout */
    CheckoutComponent,
    BasketComponent,
    BasketPreviewComponent,
    BasketPreviewArticleComponent,
    CheckoutArticleComponent,
    NewBasketComponent,
    BasketsComponent,
    BasketItemComponent,
    ItemCountComponent,
    SelectBasketTypeComponent,
    SentBasketOverviewComponent,
    GroupedArticlesComponent,

    /* Users */
    UsersComponent,

    BasketPageComponent,
    SentBasketOverviewPageComponent,
    BasketsPageComponent,
    UsersPageComponent,
    CheckoutPageComponent,
    UserSettingsPageComponent,
    UserSettingsComponent,
    EditBasketNameComponent,

    EditUserModalComponent,
    ConfirmDialogComponent,
    SelectShippingAddressComponent,
    OpenOrdersComponent,
    FinancialPageComponent,
    SelectFinancialTypeComponent,
    InvoicesComponent,
    PackingSlipsComponent,
    FinancialComponent,
    OpenOrderLineComponent,
    OpenOrdersFiltersComponent,
    FinancialPaginatorComponent,
    NumResultsOpenOrdersComponent,
    ViewLeafletComponent,
    UnlinkPopupComponent,
    EditOpenOrderComponent,
    OpenOrdersOptionsComponent,
    WidgetsComponent,

    DetailsModalComponent,
    ModalContentDirective,
    AlertCounterComponent,
    InvoiceLineComponent,
    InvoicesFiltersComponent,
    InvoiceBalanceOverviewComponent,
    PackingSlipLineComponent,
    PackingSlipsFiltersComponent,
    PackingSlipViewerComponent,
    RecentSearchedComponent,
    NewsPageComponent,
    NewsOverviewComponent,
    SearchDealersComponent,
    HelpdeskPageComponent,
    DealerListItemComponent,
    ProfilesPageComponent,
    ProfilesComponent,
    ProfileItemComponent,
    DownloadOpenOrderComponent,
    EditProfileModalComponent,
    SearchDealerModalComponent,
    SelectedDealerNavBarComponent,
    UserOptionsComponent,
    BasketOptionComponent,
    FooterComponent,
    SetItemModalComponent,
    MandatoryRequiredLabelComponent,
    ErrorPageComponent,
    PreorderBasketComponent,
    PreorderBasketPageComponent,
    EditPreorderBasketComponent,
    PreorderAlertComponent,
    PromotionBlockComponent,
    NotImplementedPageComponent,
    SelectArticleTypeComponent,
    BasketPaymentConditionsComponent,
    LogoComponent,
    EditBasketArticleModalComponent,
    WarrantyPageComponent,
    WarrantyOverviewComponent,
    WarrantyRequestComponent,
    WarrantyDetailsComponent,
    WarrantyWizardComponent,
    WarrantyDetailsArticleComponent,
    WarrantySendDetailsComponent,
    SelectPickupDateComponent,
    WarrantyLabelsComponent,
    SelectForWarrantyShipmentComponent,
    WarrantyRequestsContainerComponent,
    WarrantyGuaranteeComponent,
    WarrantyGeneralInputsComponent,
    WarrantyFramenumberInputComponent,
    BicycleFinderComponent,
    WarrantyReturnsComponent,
    WarrantyCallbackComponent,
    WarrantyArticleDescriptionInputComponent,
    WarrantyComplainDescriptionInputsComponent,
    WarrantyFileUploadComponent,
    ArticleFinderModalComponent,
    WarrantyGuaranteeDateComponent,
    PackingSlipFinderComponent,
    PackingSlipArticleListComponent,
    TurnoverStatisticsPageComponent,
    YearAgreementComponent,
    YearAgreementContainerComponent,
    YearAgreementBrandSelectionComponent,
    YearAgreementTabComponent,
    YearAgreementTabSectionComponent,
    InputLabelComponent,
    YearAgreementGroupComponent,
    YearAgreementModelsSelectionComponent,
    PurchaseDistributionComponent,
    PurchaseDistributionTableComponent,
    BonusTabComponent,
    AffiliateInformationComponent,
    NewAgreementComponent,
    ShowDealerAgreementsComponent,
    WarrantyBatteryComponent,
    InfiniteScrollComponent,
    PendingRequestsComponent,
    ShowCompareButtonComponent,
    EbikePdfContainerComponent,
    SuccessModalComponent,
    LotteryRegistrationPageComponent,
    LotteryRegistrationComponent,
    WarrantyRequestFormComponent,
    WarrantyLabelCardComponent,
    SlideshowsOverviewComponent,
    SlideshowsOverviewPageComponent,
    SlideshowModalComponent,
    SlideComponent,
    SelectCouponModalComponent,
    LeaseModelsComponent,
    OutdatedBrowserPageComponent,
    RegisterOktaComponent,
    BalanceComponent,
    BalanceLineComponent,
    BalanceFiltersComponent,
    WarrantyBatteryCallbackComponent,
    ShippingCostOverviewComponent,
    ChangePasswordModalComponent,
    SlideshowVideoEmbedComponent,
    RegisterOktaPageComponent,
    MigrateAccountComponent,
    MergeDealersPageComponent,
    WarrantyLeasingComponent,
    VerifyPageComponent,
    QrScannerComponent,
    QrScannerInputComponent,
    WishlistModalComponent,
    BicycleLeaseDetailsComponent,
    MandatorySelectModalComponent,
    PdfViewerComponent,
    ContactDetailsPageComponent,
    CompanyDataPageComponent,
    AddLeaseEmployerComponent,
    DiscountSheetPageComponent,
    SupplyAndDemandPageComponent,
    MyAdvertsComponent,
    NewSupplyComponent,
    NewDemandComponent,
    MySupplyComponent,
    MyDemandComponent,
    SupplyComponent,
    DemandComponent,
    EditDemandComponent,
    EditSupplyComponent,
    BasketSumByBrandComponent,
    DealerCardComponent,
    SearchMarketplaceResultsComponent,
    AddAffiliateComponent,
    YearAgreementContainerPartsComponent,
    DealerCommercialPartnerInformationComponent,
    DealerCommercialPartnerSettingsComponent,
    DealerPartsBrandsSelectionComponent,
    YearAgreementDealerShipsComponent,
    DrgConditionsComponent,
    DealerPartsBonusSelectionComponent,
    FavoritesButtonComponent,
    LeaveKioskModeModalComponent,
    KioskEcommerceArticlesComponent,
    PaymentSuccessComponent,
    UnmergeDealerPageComponent,
    SelectPartsDeliveryWeekComponent,
    PickupAddressSelectComponent,
    AppDefaultComponent,
    MobileNavigationComponent,
    NdaCheckComponent,
    ResendOktaActivationModalComponent,
    ResetOktaActivationModalComponent,
    StockNotificationComponent,
    StockNotificationsContainerComponent,
    BackorderAlerterComponent,
    PreorderArticleModalComponent,
    EditOpenOrderChangeBatteryComponent,
    DeletedBasketOverviewComponent,
    ArticlesInBackorderOverviewComponent,
    BarcodeComponent,
    BarCodeScannerComponent,
    SelectCheckoutShippingDateComponent,
    SelectLinkedDealerComponent,
    AccentryLogoComponent,
    SleighComponent,
    WarrantyManfacturerNotifyComponent,
    ReplenishmentSelectionModalComponent,
    SearchMenuComponent,
    DeliverWhenAllInStockComponent,
    ThreeDeeViewerModalComponent,
    GroupSelectHomepageComponent,
    BrandSelectorLargeComponent,
    StoryblokDirective,
    PageComponent,
    BannerComponent,
    StoryblokPageComponent,
    StoryblokElementsContainerComponent,
    ArticleContainerComponent,
    LinkButtonComponent,
    UncheckedArticlesNoticeComponent,
    StoryBlokPreviewPageComponent,
    DynamicGridComponent,
    GridBlockComponent,
    VideoComponent,
    CarouselPreviewComponent,
    SaveFiltersComponent,
    RecallPageComponent,
    YearAgreementPAndAComponent,
    ImageComponent,
    ArticleImageLightboxComponent,
    EzyRiderInvoiceComponent,
    SlideContentComponent,
    ImageBankConditionsModalComponent,
    RecallKogaComponent,
    CatalogueNavbarComponent,
    CatalogueDropdownComponent,
    StyleAnlComponent,
    StyleGhoComponent,
    BasketSkeletonLoaderComponent,
    ShowroomBlockerComponent,
    AdminToolsComponent,
    AdminSidebarComponent,
    AdminLinksComponent,
    TestSettingsComponent,
    JunckerPlusComponent,
    JunckerLoyaltyCountComponent,
    RedirectToJunckerPlusComponent,
    AppComponent
  ]
})
export class AppModule {}
