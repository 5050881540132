import { ElementRef, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as _ from 'underscore';
import * as appGlobal from '../globals';
import { CompanyGroup } from '../interfaces/company-group';
import { ApiResponse } from '../interfaces/api-response';
import { MenuLayout } from '../interfaces/menu-layout';
import * as moment from 'moment';
import { CompanyGroupLayout } from '../interfaces/company-group-layout';
import { forkJoin, Observable, pipe, Subject, of, Subscription } from 'rxjs';
import {
  PreorderSettings,
  PreorderSettingsContainer,
} from '../interfaces/settings/preorder-settings';
import { AccountInformation } from '../interfaces/account-information';
import { AccountSettings } from '../interfaces/account-settings';
import { Title } from '@angular/platform-browser';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from './helper.service';
import { resolve } from '@angular/compiler-cli/src/ngtsc/file_system';
import { reject } from 'underscore';
import { TranslateService } from '@ngx-translate/core';
import { VersionData } from '../interfaces/version-data';
import { MenuItem } from '../interfaces/menuItem';
import { Article } from '../interfaces/article';
import { Router } from '@angular/router';
import { AlertService } from './alert.service';
import { ShowroomBlockerComponent } from '../components/general-components/showroom-blocker/showroom-blocker.component';
import { SettingsService } from './settings.service';
import { PartnerPortalSettings } from '../interfaces/partner-portal/partner-portal-settings';
@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  public selectedCompanyGroupSettings: any = {};
  private emitShowroomModeSource = new Subject<any>();
  private emitChangeCompanyGroupSource = new Subject<any>();
  private emitChangeSource = new Subject<any>();
  private emitLanguageSource = new Subject<any>();
  private emitShowLoader = new Subject<any>();
  public emitRequestError = new Subject<any>();
  private emitModalOpened = new Subject<any>();
  searchMenuActive = new Subject<boolean>();
  changeLanguageEmitted$ = this.emitLanguageSource.asObservable();
  changeEmitted$ = this.emitShowroomModeSource.asObservable();
  emitCompareChanged$ = this.emitChangeSource.asObservable();
  emitChangeCompanyGroupChanged$ =
    this.emitChangeCompanyGroupSource.asObservable();
  emitShowLoader$ = this.emitShowLoader.asObservable();
  emitRequestError$ = this.emitRequestError.asObservable();
  emitModalOpened$ = this.emitModalOpened.asObservable();
  kioskMode: any;
  newVersionAvailable: boolean = false;
  hasDropshipment: boolean = false;
  versionChecked: boolean = false;
  versionLoaded: boolean = false;
  pageShowroomModeCheck: Subscription;
  pageShowroomModeRouteCheck: Subscription;
  private menuItemsPromise: Promise<MenuLayout> | null = null;
  reloadTranslationsInterval: any;
  constructor(
    private http: HttpClient,
    private titleService: Title,
    private helperService: HelperService,
    private translateService: TranslateService,
    private router: Router,
    private alertService: AlertService,
    private modalService: NgbModal,
    private settingsService: SettingsService,
  ) {
    this.checkKioskMode();
  }

  changeFavicon(name: string) {
    const faviconUrl = name;
    const faviconElement: HTMLLinkElement = document.querySelector('#favicon');
    faviconElement.href = faviconUrl;
  }

  setPageTitle(title: string) {
    const envTitles: any = {
      development: 'development - api: ' + environment.apiendpoint,
      production: '',
      acceptance: 'PREVIEW',
      test: 'ALPHA',
    };

    if (environment.name === "development") {
      this.changeFavicon("assets/images/favicon-dev.ico");
    }

    if (!this.isPartnerPortal()) {
      if (environment.name !== 'production') {
        this.titleService.setTitle(
          `Accentry (${envTitles[environment.name]})${title ? ' - ' + title : ''
          }`
        );
      }
    } else {
      this.settingsService.getSettings().subscribe((settings: PartnerPortalSettings) => {
        this.changeFavicon(`/assets/partner-portal/images/${settings.name}-favicon.ico`);
        this.titleService.setTitle(settings.store_title);
      })
    }
  }

  showLoader(showSpinner: boolean = true) {
    setTimeout(() => {
      this.emitShowLoader.next({ show: true, spinner: showSpinner });
    });
  }

  hideLoader() {
    setTimeout(() => {
      this.emitShowLoader.next({ show: false, spinner: false });
    });
  }

  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }

  emitShowRoomModeChange(change: any) {
    this.emitShowroomModeSource.next(change);
  }

  emitModalChange(change: any) {
    this.emitModalOpened.next(change);
  }

  // Save available company groups
  saveCompanyGroups(companyGroups: string[]): any {
    this.saveSetting('companyGroups', JSON.stringify(companyGroups));
  }

  // Gets available company groups
  getAvailableCompanyGroups(clear = false): string[] {
    if (clear) {
      localStorage.removeItem('companyGroups');
    }
    // return this.getSetting('companyGroups', '[]');
    const cgcs = this.getSetting('companyGroups', []);
    let codeList = [];
    cgcs.forEach((c: CompanyGroup) => {
      codeList.push(c.company_group_code);
    });
    return codeList;
  }

  // Get the settings for the company
  getSettingsForCompanyGroup(companyGroupCode: string, save = true) {
    const settings = this.getSetting('companyGroups', null);
    if (settings) {
      const cgcSettings: CompanyGroup = _.findWhere(settings, {
        company_group_code: companyGroupCode,
      });
      if (typeof cgcSettings !== 'undefined') {
        if (save) {
          this.selectedCompanyGroupSettings = cgcSettings;
          appGlobal.saveSettings(cgcSettings);
        }
        return cgcSettings;
      }
    }
    return {};
  }

  // 101 = Winora bicycles
  // 102 = Winora parts
  checkIfDealerHasCompany(companyId: number) {
    this.getSettingsForCompanyGroup('WG', false);
    if (localStorage.getItem('companyGroups')) {
      const value = localStorage.getItem('companyGroups');
      const companyGroups = JSON.parse(value as string);
      const winora = _.find(companyGroups, (c: CompanyGroup) => {
        return c.company_group_code === 'WG';
      }) as CompanyGroup;
      if (typeof winora !== 'undefined' && winora) {
        const companies = winora.companies;
        return companies.indexOf(companyId) >= 0;
      } else {
        return false;
      }
    }
    return false;
  }

  saveAccountSettings(accountSettings: AccountSettings) {
    appGlobal.saveAccountSettings(accountSettings);
  }

  saveAccountInformation(accountInformation: AccountInformation) {
    if (accountInformation) {
      localStorage.setItem(
        'partner_portal',
        accountInformation.profile?.friends_and_family ? '1' : '0'
      );
      appGlobal.saveAccountInformation(accountInformation);
    }
  }

  // Save selected company group
  saveSelectedCompanyGroupCode(code: string) {
    if (typeof code !== 'undefined') {
      const convertedCode = String(code).toUpperCase();
      const availableCompanyGroups = this.getAvailableCompanyGroups();
      this.getSettingsForCompanyGroup(code);
      if (availableCompanyGroups.indexOf(convertedCode) >= 0) {
        this.saveSetting('cgc', convertedCode);
        this.saveAccountInformation(null);
        appGlobal.clearAlerts();
        this.emitChangeCompanyGroupSource.next(convertedCode);
      }
    }
  }

  checkSelectedCompanyGroupCode(code: string) {
    const convertedCode = String(code).toUpperCase();
    const availableCompanyGroups = this.getAvailableCompanyGroups();
    if (availableCompanyGroups.indexOf(convertedCode) >= 0) {
      return convertedCode;
    } else {
      return availableCompanyGroups[0];
    }
  }

  // Gets the selected company group
  getSelectCompanyGroupCode() {
    let companyGroupCode = localStorage.getItem('cgc');

    if (!companyGroupCode) {
      const availableCompanyGroups = this.getAvailableCompanyGroups();

      if (availableCompanyGroups.length) {
        companyGroupCode = availableCompanyGroups[0];
        this.saveSetting('cgc', companyGroupCode);
      } else {
        companyGroupCode = 'ANL';
      }
    }

    return companyGroupCode;
  }

  private getBrandBikeCount(companyGroup: string, brand: string) {
    const companyGroupSettings: any = this.getSettingsForCompanyGroup(
      companyGroup,
      false
    );
    let bikeCount = 0;
    if (
      typeof companyGroupSettings !== 'undefined' &&
      typeof companyGroupSettings.bikes_per_brand !== 'undefined'
    ) {
      for (const key in companyGroupSettings.bikes_per_brand) {
        if (key.toUpperCase() === brand.toUpperCase()) {
          bikeCount = companyGroupSettings.bikes_per_brand[key];
        }
      }
    }
    return bikeCount;
  }

  // Get filter name of the brand
  private getBrandName(unformattedName: string, companyGroup: string) {
    const companyGroupSettings: any = this.getSettingsForCompanyGroup(
      companyGroup,
      false
    );

    let formattedBrandName = "";

    for (const key in companyGroupSettings.bikes_per_brand) {
      if (key.toUpperCase() === unformattedName.toUpperCase()) {
        formattedBrandName = key;
      }
    }
    return formattedBrandName;
  }

  // Get each brand per company with corresponding bike count
  getSelectedCompanyGroupBrands(companyGroup: string) {
    switch (companyGroup) {
      case 'ANL':
        return [
          {
            name: 'Batavus',
            type: 'bicycles',
            logo: 'batavus.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Batavus'),
            show: true,
          },
          {
            name: 'KOGA',
            type: 'bicycles',
            logo: 'koga.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'KOGA'),
            show: true,
          },
          {
            name: 'Sparta',
            type: 'bicycles',
            logo: 'sparta.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Sparta'),
            show: true,
          },
          {
            name: 'Loekie',
            type: 'bicycles',
            logo: 'loekie.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Loekie'),
            show: true,
          },
          {
            name: this.getBrandName('Carqon', companyGroup),
            type: 'bicycles',
            logo: 'carqon.png',
            bike_count: this.getBrandBikeCount(companyGroup, 'CarQon'),
            show: true,
          },
          {
            name: 'Babboe',
            type: 'bicycles',
            logo: 'babboe.png',
            bike_count: this.getBrandBikeCount(companyGroup, 'Babboe'),
            show: true,
          },
          { name: 'Juncker', type: 'parts', logo: 'juncker.png', show: true },
          {
            name: 'Van Nicholas',
            type: 'bicycles',
            logo: 'van-nicholas.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Van Nicholas'),
            show: true,
          },
        ];
      case 'COM':
        return [
          { name: 'Comet', type: 'parts', logo: 'comet.png', show: true },
        ];
      case 'RAL':
        return [
          {
            name: 'RALEIGH',
            type: 'bicycles',
            logo: 'raleigh.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Raleigh'),
            show: true,
          },
          {
            name: 'BATAVUS',
            type: 'bicycles',
            logo: 'batavus.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Batavus'),
            show: true,
          },
          {
            name: 'HAIBIKE',
            type: 'bicycles',
            logo: 'haibike.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Haibike'),
            show: true,
          },
          {
            name: 'LAPIERRE',
            type: 'bicycles',
            logo: 'lapierre.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Lapierre'),
            show: true,
          },
          {
            name: 'WINORA',
            type: 'bicycles',
            logo: 'wg.jpg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Winora'),
            show: true,
          },
          // {
          //   name: "Part",
          //   type: "parts",
          //   logo: "Raleigh-Bike-Parts.png",
          //   show: true,
          // },
        ];
      case 'LAP':
        return [
          {
            name: 'Lapierre',
            type: 'bicycles',
            logo: 'lapierre.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Lapierre')
              ? this.getBrandBikeCount(companyGroup, 'Lapierre')
              : !environment.production
                ? 123
                : 0,
            show: true,
          },
          {
            name: 'Winora',
            type: 'bicycles',
            logo: 'wg.jpg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Winora')
              ? this.getBrandBikeCount(companyGroup, 'Winora')
              : !environment.production
                ? 32
                : 0,
            show: true,
          },
          {
            name: 'Haibike',
            type: 'bicycles',
            logo: 'haibike.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Haibike')
              ? this.getBrandBikeCount(companyGroup, 'Haibike')
              : !environment.production
                ? 40
                : 0,
            show: true,
          },
          {
            name: 'Ghost',
            type: 'bicycles',
            logo: 'ghost-red.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Ghost')
              ? this.getBrandBikeCount(companyGroup, 'Ghost')
              : !environment.production
                ? 25
                : 0,
            show: true,
          },
        ];
      case 'TUN':
        return [
          {
            name: 'Tunturi',
            type: 'bicycles',
            logo: 'tunturi.png',
            bike_count: this.getBrandBikeCount(companyGroup, 'Tunturi'),
            show: true,
          },
          {
            name: 'Nishiki',
            type: 'bicycles',
            logo: 'nishiki.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Nishiki'),
            show: true,
          },
          {
            name: 'Ghost',
            type: 'bicycles',
            logo: 'ghost.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Ghost'),
            show: true,
          },

          {
            name: 'Haibike',
            type: 'bicycles',
            logo: 'haibike.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Haibike'),
            show: true,
          },
          {
            name: 'Winora',
            type: 'bicycles',
            logo: 'wg.jpg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Winora'),
            show: true,
          },
          {
            name: 'Lapierre',
            type: 'bicycles',
            logo: 'lapierre.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Lapierre'),
            show: true,
          },
          {
            name: 'Batavus',
            type: 'bicycles',
            logo: 'batavus.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Batavus'),
            show: true,
          },

        ];
      case 'VAR':
        return [
          {
            name: 'Batavus',
            type: 'bicycles',
            logo: 'batavus.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Batavus'),
            show: true,
          },
          {
            name: 'Ghost',
            type: 'bicycles',
            logo: 'ghost.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Ghost'),
            show: true,
          },
          {
            name: 'Haibike',
            type: 'bicycles',
            logo: 'haibike.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Haibike'),
            show: true,
          },
          {
            name: 'Lapierre',
            type: 'bicycles',
            logo: 'lapierre.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Lapierre'),
            show: true,
          },
          {
            name: 'Tunturi',
            type: 'bicycles',
            logo: 'tunturi.png',
            bike_count: this.getBrandBikeCount(companyGroup, 'Tunturi'),
            show: true,
          },
          {
            name: 'Winora',
            type: 'bicycles',
            logo: 'wg.jpg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Winora'),
            show: true,
          },
        ];
      case 'WG':
        return [
          {
            name: 'Winora',
            type: 'bicycles',
            logo: 'wg.jpg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Winora'),
            show: true,
          },
          {
            name: 'Haibike',
            type: 'bicycles',
            logo: 'haibike.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Haibike'),
            show: true,
          },
          {
            name: 'Batavus',
            type: 'bicycles',
            logo: 'batavus.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Batavus'),
            show: true,
          },
          {
            name: "Green's",
            type: 'bicycles',
            logo: 'greens.png',
            bike_count: this.getBrandBikeCount(companyGroup, "Green's"),
            show: true,
          },
          {
            name: 'Winora',
            type: 'parts',
            logo: 'e-wbp.png',
            show: this.checkIfDealerHasCompany(102),
          },
          {
            name: this.getBrandName('CarQon', companyGroup),
            type: 'bicycles',
            logo: 'carqon.png',
            bike_count: this.getBrandBikeCount(companyGroup, 'Carqon'),
            show: true,
          },
          {
            name: 'Babboe',
            type: 'bicycles',
            logo: 'babboe.png',
            bike_count: this.getBrandBikeCount(companyGroup, 'Babboe'),
            show: true,
          }
        ];
      case 'GHO':
        return [
          {
            name: 'Ghost',
            type: 'bicycles',
            logo: 'ghost.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Ghost')
              ? this.getBrandBikeCount(companyGroup, 'Ghost')
              : !environment.production
                ? 423
                : 0,
            show: true,
          },
          {
            name: 'Lapierre',
            type: 'bicycles',
            logo: 'lapierre.svg',
            bike_count: this.getBrandBikeCount(companyGroup, 'Lapierre')
              ? this.getBrandBikeCount(companyGroup, 'Lapierre')
              : !environment.production
                ? 53
                : 0,
            show: true,
          },
        ];
      default:
        return [];
    }
  }

  // Load all companies with corresponding brands
  getAllGroupBrands() {
    let brands: any = {};
    brands['ANL'] = this.getSelectedCompanyGroupBrands('ANL');
    brands['GHO'] = this.getSelectedCompanyGroupBrands('GHO');
    brands['LAP'] = this.getSelectedCompanyGroupBrands('LAP');
    brands['WG'] = this.getSelectedCompanyGroupBrands('WG');
    brands['TUN'] = this.getSelectedCompanyGroupBrands('TUN');
    brands['RAL'] = this.getSelectedCompanyGroupBrands('RAL');
    brands['COM'] = this.getSelectedCompanyGroupBrands('COM');
    brands['VAR'] = this.getSelectedCompanyGroupBrands('VAR');
    return brands;
  }

  getCompanyGroupLayout() {
    const selectedCompanyGroup = this.getSelectCompanyGroupCode();
    const key = `layout_${selectedCompanyGroup}`;
    const promise = new Promise((approve, reject) => {
      if (this.getTempSetting(key, false)) {
        approve(this.getTempSetting(key, null));
      } else {
        this.http
          .get(
            `${environment.apiendpoint}companygroups/${selectedCompanyGroup}/layout`
          )
          .subscribe((apiResponse: ApiResponse) => {
            if (apiResponse.success) {
              this.saveTempSetting(key, JSON.stringify(apiResponse.result));
              approve(apiResponse.result);
            }
          });
      }
    });
    return promise;
  }

  getLayoutSetting(setting: string) {
    return new Promise((approve, reject) => {
      this.getCompanyGroupLayout().then((layout: CompanyGroupLayout) => {
        approve(layout[setting]);
      });
    });
  }

  // Saves a setting to the local storage
  saveSetting(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  // Saves a temporary setting to the local storage
  saveTempSetting(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  // Deletes a setting to the local storage
  deleteStoredItem(key: string) {
    localStorage.removeItem(key);
  }

  // Deletes multiple items from localstorage
  deleteStoredItems(key: string) {
    for (const item in localStorage) {
      if (item.indexOf(key) >= 0) {
        localStorage.removeItem(item);
      }
    }
    for (const item in sessionStorage) {
      if (item.indexOf(key) >= 0) {
        localStorage.removeItem(item);
      }
    }
  }

  // Gets a settings from the local storage
  getSetting(key: string, defaultValue: string | boolean | Array<string>) {
    const data = localStorage.getItem(key);
    let response = null;
    try {
      if (data) {
        response = JSON.parse(data);
      } else {
        response = defaultValue;
      }
    } catch (err) {
      response = defaultValue;
    }
    return response;
  }

  deleteTempSetting(key: string) {
    return sessionStorage.removeItem(key);
  }

  getTempSetting(key: string, defaultValue: string | boolean) {
    return sessionStorage.getItem(key)
      ? JSON.parse(sessionStorage.getItem(key) ?? '')
      : defaultValue;
  }

  saveSelectedLanguage(setting: string) {
    this.deleteStoredItems(`${this.getSelectCompanyGroupCode()}menuItems`);
    this.saveSetting('language', setting);
    this.emitLanguageSource.next('language');
  }

  updateSelectedLanguage(selectedLanguage: string) {
    this.saveSelectedLanguage(selectedLanguage);
    return this.http.put(
      environment.apiendpoint +
      `accounts/settings/language/${selectedLanguage}`,
      selectedLanguage
    );
  }

  getSelectedLanguage() {
    return localStorage.getItem('language')
      ? localStorage.getItem('language')
      : null;
  }

  getSelectedViewType(defaultValue: string) {
    return localStorage.getItem('viewType')
      ? localStorage.getItem('viewType')
      : defaultValue;
  }

  getSelectedSorting(defaultValue: string) {
    return localStorage.getItem('sorting')
      ? localStorage.getItem('sorting')
      : defaultValue;
  }

  getSelectedPageSize(defaultValue: string) {
    return localStorage.getItem('pageSize')
      ? localStorage.getItem('pageSize')
      : defaultValue;
  }

  saveShowroomMode(setting: string) {
    this.saveSetting('showroomMode', setting);
    this.emitShowRoomModeChange('');
  }

  saveKioskMode(setting: string | boolean) {
    this.kioskMode = setting;
    this.saveSetting('kioskMode', String(setting));
    this.emitShowRoomModeChange('');
  }

  checkKioskMode() {
    if (!this.getKioskMode(false) && this.kioskMode) {
      this.saveKioskMode('0');
    }
  }

  getShowroomMode(defaultValue: string | boolean) {
    return (<unknown>(
      this.helperService.getStorageItem('showroomMode', String(defaultValue))
    )) as boolean;
  }

  getKioskMode(defaultValue: boolean) {
    return (<unknown>(
      this.helperService.getStorageItem('kioskMode', defaultValue)
    )) as boolean;
  }

  saveShowGrossPrice(setting: string) {
    this.saveSetting('showGrossPrice', setting);
    this.emitShowRoomModeChange('showGrossPrice');
  }

  // Get the selected items from the compare list
  getCompareListItems() {
    const companyGroupCode = this.getSelectCompanyGroupCode();

    return (<unknown>(
      this.helperService.getStorageItem(
        'selectForCompare_' + companyGroupCode,
        []
      )
    )) as Array<number>;
  }

  // Save or remove from compare list
  addOrRemoveToCompareList(article: Article) {
    if (article.id) {
      const compareList = this.getCompareListItems();
      const companyGroupCode = this.getSelectCompanyGroupCode();

      const currentIndex = compareList.indexOf(article.id);
      // Check if already in list
      if (currentIndex < 0) {
        if (compareList.length > 7) {
          compareList[0] = article.id;
        } else {
          compareList.push(article.id);
        }
      } else {
        compareList.splice(currentIndex, 1);
      }

      this.saveSetting(
        'selectForCompare_' + companyGroupCode,
        JSON.stringify(compareList)
      );

      this.emitChangeSource.next({
        article: article,
        selected: currentIndex < 0,
      });

      return currentIndex < 0;
    } else {
      return null;
    }
  }

  // Empty compare list
  emptyCompareList() {
    const companyGroupCode = this.getSelectCompanyGroupCode();
    localStorage.removeItem('selectForCompare_' + companyGroupCode);
    this.emitChangeSource.next(true);
  }

  getMenuItems(clear = false): Promise<MenuLayout> {
    const key = this.getSelectCompanyGroupCode() + 'menuItems';

    if (clear) {
      this.deleteTempSetting(key);
      this.menuItemsPromise = null;  // Clear the stored promise
    }

    if (!this.menuItemsPromise) {
      this.menuItemsPromise = new Promise((approve, reject) => {
        const storedMenuItems = this.getTempSetting(key, null);
        if (storedMenuItems) {
          approve(storedMenuItems);
        } else {
          this.http
            .get(environment.apiendpoint + 'menu?v=2.0')
            .subscribe({
              next: (response: ApiResponse) => {
                if (this.helperService.checkResponse(response)) {
                  const responseMenuItems = response.result;
                  this.saveTempSetting(key, JSON.stringify(responseMenuItems));
                  approve(responseMenuItems);
                }
                this.menuItemsPromise = null;  // Reset the promise if the response is invalid
              },
              error: (err) => {
                this.menuItemsPromise = null;  // Reset the promise in case of error
                reject(err);  // Optionally reject the promise
              }
            });
        }
      });
    }

    return this.menuItemsPromise;
  }

  hasMenuItem(mainGroupName: string, itemName: string) {
    return new Promise((resolve) => {
      this.getMenuItems().then((data: any) => {
        const orderItems = data?.menu_items?.find((i: MenuItem) => {
          return i.name === mainGroupName;
        });
        const hasXl = orderItems?.sub_items?.filter((i: MenuItem) => {
          return i.name === itemName;
        });
        resolve(hasXl?.length ? true : false);
      });
    });
  }

  getLanguages() {
    return this.http.get(environment.apiendpoint + 'translations/languages');
  }

  getCompanyDefaultLanguage(): string {
    const settings: CompanyGroup = this.getSettingsForCompanyGroup(
      this.getSelectCompanyGroupCode()
    ) as CompanyGroup;
    return settings?.default_language;
  }

  // Creates a querystring based on the number of items
  createQueryString(itemList = [], key: string) {
    let url = '';

    for (const item of itemList) {
      url += key + '=' + item + '&';
    }
    url = url.substring(0, url.length - 1);
    return '?' + url;
  }

  getDateOfWeek(year: number, week: number) {
    return moment().day('Monday').year(year).isoWeek(week);
  }

  getMonths() {
    let year = Number(moment().format('YYYY'));
    let monthList = [];
    for (let i = 0; i <= 1; i++) {
      const key = `${year + i}01`;
      const endKey = `${year + i}13`;
      monthList = monthList.concat(_.range(Number(key), Number(endKey), 1));
    }

    return monthList;
  }

  getMonthFromWeek(week, year) {
    let month = moment(year).add(week, 'weeks').format('M');

    return month;
  }

  getTimeslotFromMonth(month: string) {
    const formattedNumber = Number(month);
    let timeslot: string;
    const availableTimeSlots = [2, 4];
    if (availableTimeSlots.indexOf(formattedNumber) > 0) {
      timeslot =
        formattedNumber < 10 ? `0${formattedNumber}` : String(formattedNumber);
    } else {
      let timeSlotNumber = formattedNumber;

      if (timeSlotNumber < 10) {
        timeslot = `0${timeSlotNumber}`;
      } else {
        timeslot = String(timeSlotNumber);
      }
    }
    return timeslot;
  }

  getMonthFromWeekDescription(week, year) {
    let month = Number(moment(year).isoWeek(week).format('M'));

    if (month < 10) {
      return `0${month}`;
    } else {
      return String(month);
    }
  }

  getWeekFromMonth(month, year, day) {
    return Number(moment(`${month}-${day}-${year}`, 'MMDDYYYY').format('W'));
  }

  scrollToTop(position: number) {
    document.body.scrollTop = position;
    const html = document.documentElement;
    if (html) {
      html.scrollTop = position;
    }
  }

  clearPreorderSettings() {
    localStorage.removeItem(
      `preordersettings_Parts_${this.getSelectCompanyGroupCode()}`
    );
    localStorage.removeItem(
      `preordersettings_Bicycles_${this.getSelectCompanyGroupCode()}`
    );
  }

  getDate(dateObject: NgbDate) {
    if (dateObject) {
      return (
        String(dateObject.year) +
        ((dateObject.month < 10 ? '0' : '') + String(dateObject.month)) +
        ((dateObject.day < 10 ? '0' : '') + String(dateObject.day))
      );
    } else {
      return null;
    }
  }

  getWelcomeMessage() {
    let welcome_time_of_day_msg = '';
    const today = new Date();
    const current_hour = today.getHours();

    if (current_hour < 6) {
      welcome_time_of_day_msg = this.translateService.instant('GOODEVENING');
    } else if (current_hour < 12) {
      welcome_time_of_day_msg = this.translateService.instant('GOODMORNING');
    } else if (current_hour < 18) {
      welcome_time_of_day_msg = this.translateService.instant('GOODAFTERNOON');
    } else if (current_hour < 24) {
      welcome_time_of_day_msg = this.translateService.instant('GOODEVENING');
    }
    return welcome_time_of_day_msg;
  }

  setLanguage() {
    const availableLanguages = [
      'nl',
      'en',
      'de',
      'fr',
      'es',
      'da',
      'fi',
      'pl',
      'it',
      'se',
      'sv',
      'cs',
    ];
    let defaultLanguage = 'en';
    let browserLanguage = '';
    try {
      browserLanguage = window.navigator.language
        ? window.navigator.language.toLowerCase()
        : 'en';
      let language = browserLanguage.split('-')[0];
      if (availableLanguages.includes(language)) {
        // Fix for swedish
        if (language === 'sv') {
          language = 'se';
        }
        defaultLanguage = language;
      } else {
        defaultLanguage = 'en';
      }
    } catch (err) {
      defaultLanguage = 'en';
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlLanguage = urlParams.get('languageCode')
      ? urlParams.get('languageCode')
      : undefined;

    let accentryLanguage = "en";

    // set language for this user, not found: set default to english
    if (!this.getSelectedLanguage() || typeof urlLanguage !== 'undefined') {
      accentryLanguage = urlLanguage ? urlLanguage : defaultLanguage;
      this.translateService.use(accentryLanguage);
      this.saveSelectedLanguage(accentryLanguage);
    } else {
      accentryLanguage = this.getSelectedLanguage();
      this.translateService.use(accentryLanguage);
    }

    this.reloadTranslations()
  }

  // Reload translations every 8 hours
  reloadTranslations() {
    if (!this.reloadTranslationsInterval) {
      this.reloadTranslationsInterval = setInterval(() => {
        const language = localStorage.getItem("language");
        if (language) {
          this.translateService.reloadLang(language).subscribe(() => {
            this.translateService.use(language);
            console.log('Translations reloaded.');
          });
        }
      }, 28800000); // 8 hours in milliseconds
    }
  }


  getAppVersion(hasVersion) {
    return new Promise((resolve) => {
      if (hasVersion || this.versionLoaded) {
        this.versionLoaded = true;
        resolve(true);
        return;
      }

      try {
        this.getApplicationVersion().then((version: string) => {
          this.versionLoaded = true;
          localStorage.setItem("version", version);
          resolve(true);
        });
      } catch (err) {
        resolve(false);
      }
    });
  }

  // Gets the build version number of dotnetcore project
  getApplicationVersion() {
    return new Promise((resolve) => {
      this.http
        .get(
          `${!window.location.port
            ? `${window.location.origin}/`
            : environment.hostname
          }version`
        )
        .subscribe((data: VersionData) => {
          resolve(data);
        });
    });
  }

  updateApplication() {
    window.location.reload();
  }

  checkForNewVersion(version = '') {
    if (!this.newVersionAvailable) {
      let currentVersion = localStorage.getItem("version");

      if (
        typeof currentVersion === 'undefined' ||
        currentVersion === null ||
        currentVersion === ''
      ) {
        return false;
      } else {
        currentVersion = String(currentVersion);
        version = String(version);

        // version retrieved from token refresh
        if (version) {
          this.newVersionAvailable = version !== currentVersion;
          return false;
        }

        // separate call to get the version no
        this.getApplicationVersion().then((newVersion: string) => {
          if (String(newVersion) !== currentVersion) {
            this.newVersionAvailable = true;
          }
        });
      }
    }
    return false;
  }

  checkShowroomMode(enable: boolean) {

    if (!this.isPartnerPortal()) {
      // Show blocker when Showroommode is active
      const checkNotAllowed = () => {
        if (this.getShowroomMode(false)) {
          this.modalService.open(ShowroomBlockerComponent, {
            backdrop: 'static',
          });
        }
      };

      if (enable) {
        checkNotAllowed();

        // When on page check if showroommode changes
        this.pageShowroomModeCheck = this.changeEmitted$.subscribe(
          (response) => {
            checkNotAllowed();
          }
        );
      } else {
        if (typeof this.pageShowroomModeCheck !== 'undefined') {
          this.pageShowroomModeCheck.unsubscribe();
        }
      }
    }
  }

  toHomepage(alertText?) {
    if (alertText) {
      this.alertService.showDangerNotification(
        this.translateService.instant(alertText)
      );
    }
    this.router.navigateByUrl(`/${this.getSelectCompanyGroupCode()}`);
  }

  isDach() {
    return ["WG", "GHO"].includes(this.getSelectCompanyGroupCode());
  }

  isV2Basket() {
    if (localStorage.getItem("feature_BasketsV2") !== null) {
      return true
    }
  }

  isPartnerPortal() {
    return environment.shopType === "partner-portal";
  }
}
