import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from "@angular/core";
import { BasketService } from "src/app/services/basket.service";
import { BasketArticles } from "src/app/interfaces/basket-articles";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SelectCouponModalComponent } from "../../select-coupon-modal/select-coupon-modal.component";
import { ApiResponse } from "src/app/interfaces/api-response";
import { Coupon } from "src/app/interfaces/coupon";
import { BasketArticle } from "src/app/interfaces/basketArticle";
import { Router } from "@angular/router";
import { ApplicationService } from "src/app/services/application.service";
import * as _ from "underscore";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-grouped-articles",
  templateUrl: "./grouped-articles.component.html",
  styleUrls: ["./grouped-articles.component.scss"],
})
export class GroupedArticlesComponent implements OnInit {
  @Output() contentChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() editPreorderArticle: EventEmitter<any> = new EventEmitter<any>();
  @Input() allArticles: BasketArticles;
  @Input() groupedArticles: BasketArticles;
  @Input() showCheckoutOption: boolean;
  @Input() basketId = "";
  @Input() header = "";
  @Input() sum = "";
  @Input() sum_available = "";
  @Input() currency = "";
  @Input() groupId: string;
  @Input() compact: boolean; // True = for preview basket
  @Input() checkout: boolean; // True = for checkout basket
  @Input() preorder: boolean; // True = for checkout basket
  @Input() showShopDropdown: boolean; // show shop selection
  @Input() lockedDeliveryWeeks: boolean; // show delivery week
  @Input() type: string;
  @Input() wishlist: boolean;
  @Input() readonly: boolean;
  @Input() basketLocked: boolean;
  @Input() compactMode: boolean;

  selected: boolean;
  count = 0;
  @Input() coupons: Coupon[];
  showroomMode: boolean;
  viewIndex: number = 25;
  resultsPerView: number = 25;

  trackByItem(index: number, item: BasketArticle) {
    return item.id;
  }

  constructor(
    public applicationService: ApplicationService,
    private basketService: BasketService,
    private modalService: NgbModal,
    private router: Router,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    if (this.groupedArticles.items.length > 50) {
      this.viewIndex = 25;
    } else {
      this.viewIndex = 50;
    }

    this.getShowroomMode();
    this.countSelection();
    this.applicationService.changeEmitted$.subscribe((response) => {
      this.getShowroomMode();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["groupedArticles"]) {
      this.countSelection();
    }
  }

  getShowroomMode() {
    this.showroomMode = this.applicationService.getShowroomMode(false);
  }

  selectAll(data) {
    let count = 0;
    for (let i = 0; i < this.groupedArticles.items.length; i++) {
      const item = this.groupedArticles.items[i];

      if (item.item_details) {
        item.checkout = item.is_mandatory ? true : this.selected;
        count += this.selected || item.is_mandatory ? 1 : 0;
      }
    }
    this.basketService
      .updateArticleCheckoutStatus(
        this.basketId,
        this.groupedArticles.items,
        this.selected
      )
      .subscribe((response) => {
          this.contentChanged.emit("");
      });

    // Update count display
    this.groupedArticles.checkoutCount = count;
  }

  // When clicking on the checkout button in each timeslot section
  checkoutPreorderSelection() {
    this.basketService
      .updateArticleCheckoutStatus(this.basketId, this.allArticles, false)
      .subscribe((response) => {
        this.basketService
          .updateArticleCheckoutStatus(
            this.basketId,
            this.groupedArticles.items,
            true
          )
          .subscribe((selectionResponse) => {
            const groupId = !this.showCheckoutOption ? this.groupId : "all";
            this.router.navigateByUrl(
              `/${this.applicationService.getSelectCompanyGroupCode()}/preorderCheckout/${window.location.href.includes("Bikes") ? "Bikes" : "Parts"}/${this.basketId}/${groupId}`
            );
          });
      });
  }

  selectionChanged() {
    this.countSelection();
  }

  countSelection() {
    let count = 0;
    for (let i = 0; i < this.groupedArticles.items.length; i++) {
      const item = this.groupedArticles.items[i];
      if (item.item_details) {
        count += this.groupedArticles.items[i].checkout ? 1 : 0;
      }
    }
    if (count === this.groupedArticles.items.length) {
      this.selected = true;
    } else {
      this.selected = false;
    }
    this.groupedArticles.checkoutCount = count;
  }

  editPreorder() {
    this.editPreorderArticle.emit(true);
  }

  openCouponModal() {
    const modal = this.modalService.open(SelectCouponModalComponent, {
      container: "#modalContainer",
      centered: true,
      backdrop: "static",
    });

    modal.componentInstance.type = this.type;
    modal.componentInstance.coupons = this.coupons;
    modal.componentInstance.sum = this.sum_available;
    modal.componentInstance.basketId = this.basketId;
  }


  deleteArticle(article: BasketArticle) {
    this.basketService
      .deleteArticle(this.basketId, article)
      .subscribe((apiResponse: ApiResponse) => {
        if (this.helperService.checkResponse(apiResponse)) {
          this.contentChanged.emit(true);
        }
      });
  }

  getQuantity(type?: string) {
    let sum = 0;
    _.map(this.groupedArticles.items, (item) => {
      sum +=
        type === "bicycle" && item.article_type.startsWith("F")
          ? item.quantity
          : 0;
      sum +=
        type === "part" && !item.article_type.startsWith("F") ? item.quantity : 0;
      sum += !type ? item.quantity : 0;
    });

    return sum;
  }

  showMore(){
    this.viewIndex += this.resultsPerView;
  }

  nextResultsEnd(viewIndex,resultsPerView) {
    let endCount = viewIndex + resultsPerView;
    if (endCount > this.groupedArticles.items.length) {
      endCount = this.groupedArticles.items.length
    }
    return endCount;
  }
}
