import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { PartnerPortalSettings } from '../interfaces/partner-portal/partner-portal-settings';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import b2bSettings from "src/data/settings.json";
import { B2bSettings } from '../interfaces/settings/local-b2b-settings';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private style: HTMLLinkElement;
  private cssFile: string;
  private themeCSSID: string = 'themeCSS';
  settings: PartnerPortalSettings;
  b2bSettings: B2bSettings;

  constructor(private http: HttpClient,  @Inject(DOCUMENT) private document: Document) {
  }

  getSettings(noCache = false): Observable<PartnerPortalSettings> {
    const storeName = localStorage.getItem("storeName") !== null ? localStorage.getItem("storeName") : environment.storeName;

      const storageKey = `partner-portal-settings-${storeName}`

      const localStorageSettings = localStorage.getItem(
        storageKey
      );
  
      // Check if settings exist in localStorage
      if (localStorageSettings && !noCache) {
        this.settings = JSON.parse(localStorageSettings);
        // Parse the JSON string stored in localStorage
        return of(JSON.parse(localStorageSettings));
      } else {
        // Fetch settings from the JSON file
        return this.http.get(`/assets/store-settings-${storeName}.json`).pipe(
          map((response: PartnerPortalSettings) => {
            this.settings = response;
            // Store settings in localStorage
            localStorage.setItem(
              storageKey,
              JSON.stringify(response)
            );
            return response;
          }),
          catchError((error: any) => {
            // Handle error if fetching JSON file fails
            console.error('Error fetching app settings:', error);
            return of(null); // Return an empty observable or handle the error accordingly
          })
        );
      }
  }

  getB2bLocalSettings(companyGroupCode): B2bSettings {
    const settings = b2bSettings;
    this.b2bSettings = settings[companyGroupCode] as B2bSettings;
    return this.b2bSettings
  }

  hasChat(language: string) {
    return this.b2bSettings.general.chat.enabled_languages.includes(language);
  }

  setTheme(renderer2: Renderer2, storeName: string = environment.storeName) {
    if (localStorage.getItem("storeName") !== null) {
      storeName = localStorage.getItem("storeName");
    }
    this.cssFile = `assets/partner-portal/layout-${storeName}.css`;
    this.removeExistingThemeStyle(renderer2, this.themeCSSID);

    // Create a link element via Angular's renderer to avoid SSR troubles
    this.style = renderer2.createElement('link') as HTMLLinkElement;

    // Set type of the link item and path to the css file
    renderer2.setProperty(this.style, 'rel', 'stylesheet');
    renderer2.setProperty(this.style, 'href', this.cssFile);
    renderer2.setProperty(this.style, 'id', this.themeCSSID);

    // Add the style to the head section
    renderer2.appendChild(this.document.head, this.style);
  }

  removeExistingThemeStyle(renderer2: Renderer2, themeCSSID: string) {
    const themeIDHTMlElem = this.document.getElementById(themeCSSID);
    if (themeIDHTMlElem) {
      renderer2.removeChild(this.document.head, themeIDHTMlElem);
    }
  }
}
