<div class="modal-header">
  <h4
    class="modal-title"
    id="modal-basic-title"
    [innerHTML]="
      article.item_details.description +
      ' ' +
      (article.item_details.caption ? article.item_details.caption : '')
    "
  ></h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <label>{{ "DELIVERY_WEEK" | translate }}</label>
  <app-delivery-week
    class="col-4 mt-2 px-0"
    [article]="article"
    [lockedDeliveryWeeks]="lockedDeliveryWeeks"
    [deliveryWeek]="article.delivery_week"
    [deliveryYear]="article.delivery_year"
    [forceDeliveryWeekSelect]="
      (preorder && type === 'Parts') ||
      (companyGroup === 'WG' && article.item_details.company_id === '102')
    "
  >
  </app-delivery-week>
  <div class="row">
    <div class="col-12">
      <label class="mt-2">{{ "REMARKS" | translate }}</label>
      <textarea
        *ngIf="!preorder"
        class="border border-dark form-control form-control remark d-inline-block p-1"
        [(ngModel)]="article.remarks"
      ></textarea>
    </div>
    <div class="col-12" *ngIf="!preorder">
      <div class="form-check form-switch">
        <input
          type="checkbox"
          class="form-check-input"
          [id]="'sold_m_' + article.id"
          [(ngModel)]="article.sold"
        />
        <label class="form-check-label" [for]="'sold_m_' + article.id">{{
          "SOLD" | translate
        }}</label>
      </div>

      <div
        class="form-check form-switch"
        [style.display]="article.article_type === 'P' ? '' : 'none'"
      >
        <input
          type="checkbox"
          class="form-check-input"
          [id]="'back_order_m_' + article.id"
          [(ngModel)]="article.backorder"
        />
        <label class="form-check-label" [for]="'back_order_m_' + article.id">{{
          "BACKORDER_HEADER" | translate
        }}</label>
      </div>
    </div>
    <div class="col-12" *ngIf="showShopDropdown && !preorder">
      <label class="mt-2">{{ "TYPE" | translate }}</label>
      <app-shop-type-selection> </app-shop-type-selection>
    </div>
    <div class="col-5 hideForShowroomMode">
      <label class="mt-2">{{ "QUANTITY" | translate }}</label>
      <input
        type="number"
        numberOnly
        [allowDecimals]="false"
        [(ngModel)]="article.quantity"
        min="1"
        max="999"
        class="form-control form-control w-75"
        style="max-width: 60px"
      />
    </div>
    <div class="col-7 ps-0 text-end">
      <label class="d-block mt-2 mb-4">{{ "TOTAL" | translate }}</label>
      <div class="border-bottom">
        {{ article.quantity }} &times;
        <app-nett-price
          *ngIf="article.price"
          [price]="article.price"
          class="price"
        >
        </app-nett-price>
      </div>
      <br />
      <app-article-sum
        class="w-100"
        [netPrice]="article.price"
        [retailPrice]="article.item_details.retail_price"
        class="price"
        [amount]="article.quantity"
      ></app-article-sum>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex w-100">
    <i
      class="material-icons me-auto clickable withOpacity text-danger"
      style="opacity: 1"
      (click)="deleteArticle()"
      *ngIf="!article.is_mandatory"
      >delete_forever</i
    >
    <button type="button" class="btn btn-success ms-auto" (click)="save()">
      {{ "SAVE" | translate }}
    </button>
  </div>
</div>
